import React from "react";
import { graphql, Link } from "gatsby";
import {
  mergeMenuRawItems,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
import BlogPostPreviewList from "../components/blog-post-preview-list";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      hotlineNumber
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    categories: allSanityCategory {
      edges {
        node {
          id
          title
          description
        }
      }
    }
    navMenus: allSanityNavigationMenu {
      edges {
        node {
          id
          _key
          title
          ...NavMenu
        }
      }
    }
  }
`;

const BlogIndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const categories = (data || {}).categories ? mapEdgesToNodes(data.categories) : [];

  const menuNodes = (data || {}).navMenus ? mapEdgesToNodes(data.navMenus) : [];
  const mainMenu = mergeMenuRawItems(
    menuNodes.filter((menu) => menu.title === "Main nav menu")[0] || {}
  );

  const sideMenu = mergeMenuRawItems(
    menuNodes.filter((menu) => menu.title === "Side nav menu")[0] || {}
  );

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    console.warn(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout
      navMenuItems={(mainMenu || {}).items || []}
      hotlineNumber={site.hotlineNumber}
      showNav={true}
      textWhite={false}
    >
      <SEO
        title={site.title || "Missing title"}
        description={site.description || "Missing description"}
        keywords={site.keywords || []}

      />
      <div className="container mx-auto my-24 flex flex-wrap">
        <div className="w-full md:w-2/3">
          <div className="-mt-4">{postNodes && <BlogPostPreviewList nodes={postNodes} />}</div>
        </div>
        <div className="w-full md:w-1/3 md:pl-4">
          <Link to="/gioi-thieu">
            <div className="mb-4 cursor-pointer">
              <h3 className="text-2xl font-bold mb-2 text-blue-800">Giới thiệu</h3>
              <img className="w-100" src="/mat-sau.jpg" alt="Giới thiệu" />
            </div>
          </Link>
          {sideMenu &&
            sideMenu.items &&
            sideMenu.items.map((item) => {
              return (
                <div key={item._key} className="mb-4">
                  <h3 className="text-2xl font-bold mb-2 text-blue-800 hover:underline">
                    <a href={item.route || item.link || "#"} title={item.title}>
                      {item.title}
                    </a>
                  </h3>
                  {item.items && (
                    <ul>
                      {item.items.map((subItem) => (
                        <li key={subItem._key} className="mb-2 pl-2">
                          <a
                            className="no-underline hover:underline"
                            href={subItem.route || subItem.link || "#"}
                            title={item.title}
                          >
                            {subItem.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default BlogIndexPage;
